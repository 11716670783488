<template>
  <div>
    <Card>
      <template slot="outer">
        <div class="mb-4 flex justify-between align-center">
          <div class="py-3 inline-flex align-center">
            <md-icon>list</md-icon>
            <h3 class="pl-2 text-uppercase">Study Plan List</h3>
          </div>
          <div class="flex">
            <div class="md-layout-item flex justify-end">
            <div class="flex align-center">
              <md-icon>date_range</md-icon>
              <md-switch
                v-model="boolean"
                class="px-2 m-0"
              ></md-switch>
              <router-link :to="{name: 'studyPlan.list'}"><md-icon>format_list_bulleted</md-icon></router-link>
            </div>
          </div>
          </div>
        </div>
      </template>
      <CardBody class="p-3 mb-16">
        <Calendar class="my-8" />
      </CardBody>
    </Card>
  </div>
</template>

<script>
import { Card, CardBody } from "@/components";
import { mapMutations } from "vuex";
import Calendar from "@/components/atom/Calendar";

export default {
  name: "Todo-List",
  components: {
    Card,
    CardBody,
    Calendar
  },
  data() {
      return {
          boolean: true
      }
  },
  methods: {
    ...mapMutations({
      dialog: "setShowDialog",
    })
  },
};
</script>